<template lang="html">
  <section class="team-list mb-3">

    <div class="field-label">{{$t('team')}}</div>
    <form @submit.prevent="onAddTeam" v-if="!isEditMode">
      <div class="mb-2">
        <v-select v-if="categoryTeams.length > 0" 
          id="event-nation" v-model="newTeam"  class="form-control"
         :options="categoryTeams" @input="onSelectCategoryTeam" :clearable="false"
         :placeholder="$t('selectTeam')">
        </v-select>
        <div class="d-flex" v-else>
          <b-input-group>
            <b-form-input v-model="newTeam" type="text" :placeholder="$t('teamName')" autocomplete="off"></b-form-input>
            <b-input-group-append>
            <b-button variant="primary" class="add todo-list-add-btn text-uppercase" id="add-team" v-on:click="onAddTeam">{{$t('add')}}</b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
    </form>

    <div>
      <div class="teams" v-for="(team, index) in teams" :key="team.name">
      <blockquote class="blockquote blockquote-warning mb-0">
        <div v-if="!isEditMode" class="small text-warning text-center">{TEAM{{index+1}}}</div>
        <img :src="GetLogoUrl(team.name)" class="img-xxs rounded-circle mr-2" />
        <quick-edit v-model="team.name" v-bind:showButtons="false" @input="onChangeTeam()" class="text-warning" v-if="!isEditMode"/> <span class="text-warning" v-else>{{team.name}}</span>
        <i @click.prevent="deleteTeam(team)" class="remove mdi mdi-close icon-sm text-danger pl-2"  v-if="!isEditMode" />
        </blockquote>
    </div>
    </div>
  </section>
</template>

<script lang="js">
export default {
  name: 'team-list',
  data () {
    return {
      newTeam: ''
    }
  },
  props: {
    eventId: String,
    mode: String,
    isUserDefinedCategory: Boolean,
    teams: {
      type:Array,
      default: function(){return[]}
    },
    categoryTeams: {
      type:Array,
      default: function(){return[]}
    }
  },
  computed: {
    isEditMode: function(){
      return this.mode === 'edit';
    }
  },
  methods: {
    onChangeTeam(){
      this.updateTeam();
    },
    onSelectCategoryTeam(value){
       this.addTeam(value).then(() => {
        this.newTeam = '';
      });
    },
    onAddTeam(){
      if (!this.newTeam == '') {
         this.addTeam(this.newTeam).then(() => {
          this.newTeam = '';
        });
      }
    },
    async addTeam (teamName) {
      this.teams.push({name: teamName});
      this.updateTeam();
    },
    deleteTeam (team) {
      const teamIndex = this.teams.indexOf(team);
      this.teams.splice(teamIndex, 1);      
      this.updateTeam();
    },
    updateTeam(){
      this.$emit('update:teams',this.teams);
    }
  }
}
</script>

<style lang="scss">
.team-list {
    .list-wrapper {
        max-height: 300px;
        overflow-y: auto;
    }
    .add-items {
        margin-bottom: 0.5rem;
    }
    .teams {
        display: inline-block;
        margin-right: 5px;
    }
    .remove {
        width: initial !important;
        color: #d53f3a !important;
        cursor: pointer;
    }
    .vue-quick-edit {
      display: inline-block;
    }
}
</style>

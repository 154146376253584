<template>
<section class="creategame">
    <div class="page-header">
      <h3 v-if="isEditMode" class="page-title">{{$t('editEvent')}}</h3>
      <h3 v-else class="page-title">{{$t('createEvent')}}</h3>
    </div>

    <div v-if="isCreateMode && !IsAdminPage()" class="pb-2 text-warning text-small"><i class="fas fa-info-circle" v-b-tooltip /> {{$t('createFeeMessage')}} <a href="https://docs.bob.win/ecosystem/bookmaker/how-to-create-an-event" target="_blank" class="text-info">{{$t('moreDetails')}}</a></div>

    <div class="row" v-if="isCreateMode && !IsAdminPage()">
      <div class="col-md-12 grid-margin stretch-card">
          <div :class="'card ' + GetCurrentRole()">
              <div class="card-body">
                  <b-form-group :label="$t('recommendedEvents')" label-for="event-template" class="mb-0">
                    <v-select v-model="templateEventId"
                      :options="eventTemplateOptions"
                      :reduce="evt => evt.id" label="title"
                      :clearable="false"
                      @input="onTemplateSelect"
                      id="event-template" :placeholder="$t('selectRecommendedEvents')" class="form-control" />
                  </b-form-group>
              </div>
          </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
          <div :class="'card ' + GetCurrentRole()">
              <div class="card-body">
                <b-form id="create-game">

                  <b-form-group :label="$t('quickSelect')" v-if="isCreateMode">
                    <b-button v-for="quickCategory in quickCategories" :key="quickCategory.id" variant="inverse-info" size="sm" class="btn btn-rounded mr-2 mb-2" @click="onSelectQuickCategory(quickCategory)">{{quickCategory.title}}</b-button>
                  </b-form-group>

                  <create-category :eventCategoryId="event.eventCategoryId" :mode="mode" @on-category-change="onCategoryChange" />

                  <b-form-group :label="$t('nation')" label-for="event-nation">
                    <v-select v-model="event.nation.id"
                      id="event-nation" :placeholder="$t('selectNation')" class="form-control"
                      :options="nationOptions"
                      :reduce="nation => nation.id" label="name"
                      :clearable="false"
                      :disabled="isEditMode || event.eventCategoryId == ''"
                      @input="onNationChange"></v-select>
                  </b-form-group>
                  <b-form-group :label="$t('league')" label-for="event-subcategory">
                    <v-select v-model="event.subcategory.id"
                      :options="subcategoryOptions"
                      :reduce="cate => cate.id" label="name"
                      :clearable="false"
                      :disabled="isEditMode || event.nation.id == ''"
                      @input="onSubcategoryChange"
                      id="event-subcategory" :placeholder="$t('selectLeague')" class="form-control" />
                  </b-form-group>

                  <div class="row">
                    <div class="col-sm-6 col-xl-3 mb-3">
                      <div class="text-muted field-label">{{$t('eventDate')}}</div>
                        <VueCtkDateTimePicker v-model="displayStartDate"
                          :label="$t('startDateLabel')"
                          id="game-start-date" locale="en" format="YYYY-MM-DD HH:mm:ss" dark
                          :no-header="true" :no-label="true" :no-clear-button="true" :no-button-now="true"
                          :min-date="getMinDate()"
                          :minute-interval="5"
                          :formatted="displayDateFormat"
                          :disabled="isEditMode"
                          @input="onEventDateChange" />
                      </div>
                      <div class="col-sm-6 col-xl-3 mb-3">
                        <b-form-group :label="$t('timezone')" label-for="timezone">
                          <v-select v-model="selectedTimezone"
                            :options="timezoneList"
                            label="timezone"
                            :clearable="false"
                            :disabled="isEditMode"
                            id="event-timezone" class="form-control" />
                        </b-form-group>
                      </div>
                    </div>

                    <div class="row">
                    <div class="col-lg-6 col-xl-3">
                      <div class="text-muted field-label">{{$t('voteStart')}} <span class="small text-more-muted" v-if="!isEditMode">({{$t('eventDate')}} + {{$t('hours')}})</span></div>
                        <div :class="dateFieldClass">
                          <div class="mr-2" v-if="isCreateMode">
                            <create-time :defaultValue="event.endDuration" :min="0" :max="24" :interval="1" @on-date-change="onDateChange('enddate', $event)" :disabled="displayStartDate==''|| displayStartDate==null" /></div>
                          <div><b-form-input v-model="displayEndDate" type="text" disabled class="mb-3" /></div>
                        </div>
                    </div>
                    
                    <div class="col-lg-6 col-xl-3">
                      <div class="text-muted field-label">{{$t('voteDeadline')}} <span class="small text-more-muted" v-if="!isEditMode">({{$t('voteStart')}} + {{$t('hours')}})</span></div>
                        <div :class="dateFieldClass">
                          <div class="mr-2" v-if="isCreateMode">
                            <create-time :defaultValue="event.voteDuration" :min="1" :max="24" :interval="1"  @on-date-change="onDateChange('votedeadline', $event)" :disabled="displayStartDate==''|| displayStartDate==null" />
                          </div>
                          <div><b-form-input v-model="displayVoteDeadline" type="text" disabled class="mb-3"/></div>
                        </div>  
                    </div>

                    <div class="col-lg-6 col-xl-3">
                      <div class="text-muted field-label">{{$t('appealDeadline')}} <span class="small text-more-muted" v-if="!isEditMode">({{$t('voteDeadline')}} + {{$t('hours')}})</span></div>
                        <div :class="dateFieldClass">
                          <div class="mr-2" v-if="isCreateMode">
                            <create-time :defaultValue="event.claimDuration" :min="1" :max="24" :interval="1" @on-date-change="onDateChange('claimdeadline', $event)" :disabled="displayStartDate==''|| displayStartDate==null" />
                          </div>
                          <div><b-form-input v-model="displayClaimDeadline" type="text" disabled class="mb-3"/></div>
                        </div>
                    </div>  
                  </div>

                  <b-form-group :label="$t('enableBettingAfterEventStart')" v-if="!IsAdminPage()">
                    <b-form-checkbox v-model="event.enableBet" name="check-button" size="lg" switch  @change="onEventEnableChange()" :disabled="!isEditable" class="mt-2" />
                  </b-form-group>

                  <create-team :teams.sync="event.teams" :eventId="eventId" :mode="mode" :categoryTeams="categoryTeams" :isUserDefinedCategory="isUserDefinedCategory" />

                  <b-form-group :label="$t('eventTitle')" label-for="event-title" v-if="!IsAdminPage()">
                    <b-form-input v-model="event.title" id="event-title" type="text" :placeholder="$t('defaultEventTitle')" :disabled="isEditMode" autocomplete="off" />
                  </b-form-group>

                  <create-game :games.sync="event.games" :eventId="eventId" :teams="event.teams" :mode="mode" :eventStatus="event.eventStatus" @on-seedmoney-change="onSeedmoneyChange" />
  
                  <game-template :games.sync="event.games" :teams="event.teams" :mode="mode" />

                  <b-btn v-if="isCreateMode" :disabled="event.games.length == 0 || event.teams.length == 0" @click.prevent="onEventCreate" class="btn-lg mt-4 text-uppercase" variant="primary" block>{{ $t('createEvent')}}</b-btn>

                  <b-btn v-if="isEditMode && !event.hasBet && event.eventStatus == 0" @click.prevent="onDeleteEvent" class="btn-lg mt-4 text-uppercase" variant="danger">{{$t('deleteEvent')}}</b-btn>

                </b-form>
              </div>
              <div v-if="isEditMode" class="mb-4 mr-4 text-right small text-more-muted">Event ID: {{event.id}}</div>
          </div>
      </div>
    </div>
  </section>
</template>

<script>
import createTeam from './share/create-event-team'
import createGame from './share/create-event-game'
import createCategory from './share/create-event-category'
import createTime from './share/create-event-time'
import gameTemplate from './share/event-game-template'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'

export default {
  components: {
    createCategory,
    createTime,
    createTeam,
    createGame,
    gameTemplate,
    VueCtkDateTimePicker
  },
  data() {
    return {
      mode: '',
      footerBgVariant: 'warning',
      eventId: '',
      rawEvent:[],
      rawNations: [],
      rawCategoryEvents: [],
      
      templateEventId: '',
      eventTemplateOptions: [],
      categoryOptions: [],
      subcategoryData: [],
      subcategoryOptions: [],
      nationOptions: [],

      categoryTeams: [],
      selectedCategory: {},
      hasSubcategories: false,
      enableFields: {
        nation: false,
        subcategory: false,
        startdate: false,
        endDate: false,
        voteDeadline: false,
        claimDeadline: false,
        title: false
      },
      displayStartDate: '',
      displayDateFormat: 'YYYY-MM-DD HH:mm',
      displayEndDate: '',
      displayVoteDeadline: '',
      displayClaimDeadline:'',
      event: {
        id: this.eventId,
        teams: [],
        games: [],
        nation: { id: '' },
        subcategory: { id: ''},
        enableBet: false
      },
      inputSearchTemplate: '',
      templateKey: 0,
      templates: {
        id: "root",
        children: []
      },
      selectedTimezone: '',
      timezoneList: [],
      quickCategories: [],
      selectedQuickCategory: []
    }
  },
  computed: {
    eventTitle: function(){
      let team1 = this.event.teams && this.event.teams.length > 0 ? this.event.teams[0].name : '';
      let team2 = this.event.teams && this.event.teams.length > 1 ? this.event.teams[1].name : '';
      return team1 != '' ? team1 + ' vs ' + team2 : '';
    },
    isEditMode: function(){
      return this.mode === 'edit';
    },
    isCreateMode: function(){
      return this.mode === 'create';
    },
    dateFieldClass: function(){
      return this.mode === 'edit' ? '' : 'd-flex';
    },
    isUserDefinedCategory: function(){
      return (this.selectedCategory && this.selectedCategory.name === 'Specials');
    },
    isEditable: function(){
      return this.mode === 'create' || this.GetEventStatus(this.event.eventStatus) === 'OPEN';
    },
  },  
  created() {
    this.init();
    this.$root.$on('reload', () => { this.init(); });

    this.$bobwinHub.$on('subcategoriesReceived', (response) => {
      this.subcategoryData = JSON.parse(response.data);
      this.hasSubcategories = this.subcategoryData.length > 0;
      this.setSubcategoryOption(this.event.nation.id);
    });
    
    this.$bobwinHub.$on('nationsReceived', (response) => {
      this.rawNations = JSON.parse(response.data);
      this.setNationOptions(this.rawNations);
    });

    this.$bobwinHub.$on('categoryDetailsReceived', (response) => {
      let data = JSON.parse(response.data);
      this.rawCategoryEvents = data.categoryEvents;

      let teams = [];
      data.categoryTeams.forEach((team) => {
        teams.push(team.teamName);
      });
      this.categoryTeams = teams;
    });

    this.$bobwinHub.$on('eventTemplateReceived', (response) => {
      let results = JSON.parse(response.data);
      let templates = [];
      results.forEach((evt)=> {
        let elements = [];
        elements.push(this.GetDate(evt.startDate, this.displayDateFormat)); //localtime
        elements.push(evt.eventCategoryName);
        if(evt.eventCategoryName !== evt.eventSubcategoryName){
          elements.push(evt.eventSubcategoryName);
        }
        elements.push(evt.nation.name);
        elements.push(evt.title);
        templates.push({id: evt.id, title: elements.join('  / ')});
      });
      this.eventTemplateOptions = templates;
    });

    this.$bobwinHub.$on('eventReceived', (response) => {
      this.event = JSON.parse(response.data);
      if(this.event) {
        this.selectedTimezone = this.event.isReferenceEvent ? this.$moment.tz.guess() : this.event.timezone;
        this.displayStartDate = this.convertUTCToSelectedTimezone(this.event.startDate);

        this.displayEndDate = this.GetDate(this.$moment(this.displayStartDate).add(this.event.endDuration, 'hours'), this.displayDateFormat, false);
        this.displayVoteDeadline = this.GetDate(this.$moment(this.displayEndDate).add(this.event.voteDuration, 'hours'), this.displayDateFormat, false);
        this.displayClaimDeadline = this.GetDate(this.$moment(this.displayVoteDeadline).add(this.event.claimDuration, 'hours'), this.displayDateFormat, false);
        
        this.event.games.forEach(game=>game.useSharedSeedMoney = !this.isEditMode ? true : game.useSharedSeedMoney);
        this.getSubcategoryData();
      }
    });

    this.$bobwinHub.$on('eventCreated', (response) => {
      if(response.result === 'success') {
        let user = JSON.parse(response.userData);
        this.$store.dispatch('setUser', user);
        let path = this.IsAdminPage()? 'admin/myevent' : 'myevent';
        if (this.$route.path !== path) this.$router.push({ name: path});
      }
      else {
        this.ShowErrorMessage(response.message);
      }
    });

    this.$bobwinHub.$on('eventDeleted', response => {
      if(response.result === 'success') {
        this.$swal({
            icon: 'success',
            title: this.$t('eventDeleted'),
            animation: false,
            confirmButtonText: this.$t('ok')
        }).then(() => {
          let user = JSON.parse(response.userData);
          this.$store.dispatch('setUser', user);
          let path = this.IsAdminPage()? 'admin/myevent' : 'myevent';
          if (this.$route.path !== path) this.$router.push({ name: path});
        });
      }
      else {
        this.ShowErrorMessage(response.message);
      }
    });

    this.$bobwinHub.$on('eventUpdated', response => {
      if(response.result !== 'success') {
        this.ShowErrorMessage(response.message);
      }
    });

    this.$bobwinHub.$on('quickCategoriesReceived', response => {
      this.quickCategories = JSON.parse(response.data);
    });
  },
  watch: {
    $route () { this.init(); },
    'displayEndDate': function(value){
      if(this.event.voteDuration && value){
        var voteDeadline = this.GetDate(this.$moment(value).add(this.event.voteDuration, 'hours'), this.displayDateFormat, false);
        this.displayVoteDeadline = voteDeadline;
      }
    },
    'displayVoteDeadline': function(value){
      if(this.event.claimDuration && value){
        var claimDeadline = this.GetDate(this.$moment(value).add(this.event.claimDuration, 'hours'), this.displayDateFormat, false);
        this.displayClaimDeadline = claimDeadline;
      }      
    }
  },
  methods: {
    init(){
      this.getQuickCategories();
      this.getAllNations();
      this.getTimezoneList();

      if(!this.IsAdmin && (this.$route.name === 'admin/createevent' || this.$route.name === 'admin/editevent')){
        if (this.$route.path !== '/'){
          this.$router.push({ path: '/'});
        }
      } else {

        if(this.$route.params.eventId){
          this.eventId = this.$route.params.eventId;
          this.mode = 'edit';
          let param = {
            EventId: this.$route.params.eventId,
            UserId: this.UserId
          };
          this.CallHub({task: 'GetEventDetails', callback: 'eventReceived', data: JSON.stringify(param)});
        } else {
          this.mode = 'create';
          this.event = {
            eventCategoryId: '',
            endDuration: 3,
            voteDuration: 24,
            claimDuration: 24,
            games: [],
            teams: [],
            nation: {id: '' },
            subcategory: {id: ''},
            enableBet: false
          };
          this.displayEndDate = '';
          this.displayVoteDeadline = '';
          this.displayClaimDeadline = '';
          this.CallHub({task: 'GetAllAdminEvents', callback: 'eventTemplateReceived'});
        }
      }
    },
    getTimezoneList(){
      this.timezoneList = this.$moment.tz.names();
      this.selectedTimezone = this.$moment.tz.guess();
    },
    onTemplateSelect(){
      this.event.referencedEventId = this.templateEventId;
      let param = { EventId: this.templateEventId, IsReferenceEvent: true };
      this.CallHub({task: 'GetEventDetails', callback: 'eventReceived', data: JSON.stringify(param)});
    },
    initDates(){
        if(this.isCreateMode){
          this.event.startDate = null;
          this.displayStartDate = null;
          this.displayEndDate = '';
          this.event.endDate = '';
          this.displayVoteDeadline = '';
          this.event.voteDeadline = '';
          this.displayClaimDeadline = '';
          this.event.claimDeadline = '';
        }
    },
    getAllNations(){
      if(this.rawNations.length == 0){
        this.CallHub({task: 'GetAllNations', callback: 'nationsReceived'});
      } else {
        this.setNationOptions(this.rawNations);
      }
    },
    getQuickCategories(){
      this.CallHub({task: 'GetQuickCategories', callback: 'quickCategoriesReceived', data: false});
    },
    onSelectQuickCategory(quickCategory){
      this.selectedQuickCategory = quickCategory;
      this.event.eventCategoryId = quickCategory.eventCategory.id;
      this.event.nation.id = quickCategory.nation.id;
      this.getSubcategoryData();
    },    
    onCategoryChange(selectedCategory){
      this.event.eventCategoryId = selectedCategory.id;
      this.selectedCategory = selectedCategory;
      this.hasSubcategories = selectedCategory.hasSubcategories;

      if(this.isCreateMode){
        this.initSubcategory();
        this.event.subcategory.id = '';
        this.event.nation.id = '';
      }
      if(!selectedCategory.isInit){
        this.event.title = '';
        this.event.teams = [];
        this.event.games = [];
        
        this.initDates();
        this.categoryTeams = [];
      }
      this.event.referencedEventId = '';
      this.getSubcategoryData();
    },
    onNationChange(value){
      if(value != ''){
        this.event.referencedEventId = '';
        this.setSubcategoryOption(value);
      }
    },
    setNationOptions(nationData){
      let options = [];
      nationData.forEach((nation) => {
        if(nation) {
          let found = options.find(element => element.id == nation.id);
          if(!found)  options.push({id: nation.id, name: nation.name});
        }
      });
      this.nationOptions = options;
      this.onNationChange(this.event.nation.id);
    },
    initSubcategory(){
      this.event.subcategory.id = '';
      this.subcategoryOptions = [];
      this.subcategoryData = [];
    },
    onSubcategoryChange(){
      this.event.referencedEventId = '';
      this.getEventCategoryDetail(this.event.subcategory.id);
    },
    getSubcategoryData(){
      let param = {
        Id: this.event.eventCategoryId,
        IncludeDisabledData: this.isEditMode
      }
      this.CallHub({task: 'GetEventSubcategories', callback: 'subcategoriesReceived', data: JSON.stringify(param)});
      this.getEventCategoryDetail(this.event.eventCategoryId);
    },
    getEventCategoryDetail(categoryId){
      let param = {
        Id: categoryId,
        IncludeDisabledData: this.isEditMode
      }
      this.CallHub({task: 'GetEventCategoryDetails', callback: 'categoryDetailsReceived', data: JSON.stringify(param)});
    },   
    setSubcategoryOption(nationId){
      if(this.hasSubcategories) {
        if(this.isCreateMode && this.event.referencedEventId == ''){
          this.event.subcategory.id = '';
        }
        let options = [];
        let filteredSubcategories = this.subcategoryData.filter((subcategory) => (subcategory.nation && subcategory.nation.id == nationId) || subcategory.nation == null);
        filteredSubcategories.forEach((sub) => {
          options.push( {id: sub.id, name: sub.name} );
        });
        this.subcategoryOptions = options;

        if(this.selectedQuickCategory.subcategory){
          this.event.subcategory.id = this.selectedQuickCategory.subcategory.id;
          this.getEventCategoryDetail(this.event.subcategory.id);
          this.selectedQuickCategory = {};
        }
        else if(options.length == 1 && options[0].name == 'Unlisted') {
          this.event.subcategory.id = options[0].id;
        }        
      }
    },
    onDateChange(type, value){
      if(value >= 0) {
        switch(type){
          case 'enddate':
            var endDate = this.GetDate(this.$moment(this.displayStartDate).add(value, 'hours'), this.displayDateFormat, false);
            this.event.endDuration = value;  
            this.displayEndDate = endDate;
            this.enableFields.voteDeadline = true;
            break;
          case 'votedeadline':
            var voteDeadline = this.GetDate(this.$moment(this.displayEndDate).add(value, 'hours'), this.displayDateFormat, false);
            this.event.voteDuration = value;
            this.displayVoteDeadline = voteDeadline;
            this.enableFields.claimDeadline = true;
            break;
          case 'claimdeadline':
            var claimDeadline = this.GetDate(this.$moment(this.displayVoteDeadline).add(value, 'hours'), this.displayDateFormat, false);
            this.event.claimDuration  = value;
            this.displayClaimDeadline = claimDeadline;
            break;
        }
      }
    },
    getMinDate () {
      let today = new Date();
      let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+ today.getDate();
      let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      let dateTime = date +' '+ time;
      return dateTime;
    },
    onEventDateChange(){
      if(!this.displayStartDate && this.displayStartDate == null) {
        this.initDates();
      } else {
        this.enableFields.voteDeadline = this.event.voteDuration != '';
        this.enableFields.claimDeadline = this.event.claimDuration != '';
        this.onDateChange('enddate', this.event.endDuration);
      }
    },
    onSeedmoneyChange(index){
      let game = this.event.games[index];
      game.seedmoneyState = '';
    },
    convertToUTC(date){
      let localTime = this.$moment.tz(date, this.selectedTimezone);
      let utcTime = this.$moment.utc(localTime).format('YYYY-MM-DD HH:mm:ss');
      return utcTime;
    },
    convertUTCToSelectedTimezone(date) {
      var converted = this.$moment.utc(date).clone().tz(this.selectedTimezone).format(this.displayDateFormat);
      return converted;
    },
    onEventCreate() {
      let valid = true;
      let errorMessage = '';

      if(this.displayStartDate == null) {
        valid = false;
        errorMessage = this.$t('selectEventDate');
      }
      else if(!this.IsAdminPage()){
        this.event.games.forEach(game => {
          let noSeedmoney = !game.useSharedSeedMoney && (game.seedMoney == '0' || game.seedMoney == '');
          game.seedmoneyState = noSeedmoney ? 'warning' : '';
          valid = valid ? !noSeedmoney : valid;
          if(!valid){
            errorMessage = this.$t('enterSeedmoney');
          }
        });
      }
      
      if(!valid){
        this.$swal({
          icon:'warning',
          title: errorMessage,
          animation: false,
          confirmButtonText: this.$t('ok')
        })
      } else {
        this.$swal({
          icon:'info',
          title: this.$t('confirmCreateEvent'),
          footer: this.IsAdminPage() ? '' : this.GetModalFooter(this.$t('createFeeMessage')),
          animation: false,
          showCancelButton: true,
          confirmButtonText: this.$t('ok'),
          cancelButtonText: this.$t('cancel'),
        }).then((result) => {
          if (result.value) {
              let param = {
                eventCategoryId: (this.event.subcategory.id != null && this.event.subcategory.id !== '') ? this.event.subcategory.id : this.event.eventCategoryId,
                nation: this.event.nation,
                title: this.event.title != '' && typeof this.event.title !== 'undefined' ? this.event.title : this.eventTitle,
                timezone: this.selectedTimezone,
                startDate: this.convertToUTC(this.displayStartDate),
                endDate: this.convertToUTC(this.displayEndDate),
                endDuration: this.event.endDuration,
                voteDeadline: this.convertToUTC(this.displayVoteDeadline),
                voteDuration: this.event.voteDuration,
                claimDeadline: this.convertToUTC(this.displayClaimDeadline),
                claimDuration: this.event.claimDuration,
                teams: this.event.teams,
                games: this.event.games,
                userId: this.UserId,
                referencedEventId: this.templateEventId,
                isAdminCreated: this.IsAdminPage(),
                enableBet: this.event.enableBet
              };
              this.CallHub({task: 'CreateEvent', callback: 'eventCreated', data: JSON.stringify(param)});
          }
        });
      }
    },
    onDeleteEvent(){
      this.$swal({
        title: this.$t('confirmDeleteEvent'),
        icon: 'warning',
        animation: false,
        showCancelButton: true,
        confirmButtonText: this.$t('ok'),
        cancelButtonText: this.$t('cancel')
      }).then((result) => {
        if (result.value) {
          let event = {
            Id: this.eventId,
            UserId: this.UserId
          };
          this.CallHub({task: 'DeleteEvent', callback: 'eventDeleted', data: JSON.stringify(event)});
        }
      });
    },
    onEventEnableChange(){
      if(!this.isCreateMode){
        let event = {
            Id: this.eventId,
            EnableBet: this.event.enableBet,
            UserId: this.UserId
        };
        this.CallHub({task: 'UpdateEvent', callback: 'eventUpdated', data: JSON.stringify(event), broadcast: 'EventUpdated'});
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.creategame {
  min-height: 600px;
}
.btn-add-game-type {
    background: #24272a;
}
.col-form-label {
  font-size: 0.875rem;
    line-height: 1;
    vertical-align: top;
}
.option-input {
  height: calc(1.55rem + 2px);
  label {
    font-size: 0.8rem;  
  }
}
</style>
<template>
    <div class="mb-2">
        <b-button v-if="games.length > 0" v-on:click="onSaveTemplate" variant="primary" class="mr-2">{{$t('saveGameTemplate')}}</b-button>
        <span class="d-inline-block" tabindex="0" v-b-tooltip.top :title="teams.length < 2 ? $t('addTeamsMessage') : ''">
          <b-button v-if="isCreateMode" :disabled="teams.length < 2" @click="onLoadTemplate" variant="success">{{$t('loadGameTemplate')}}</b-button>
        </span>
        
        <b-modal ref="templateModal" id="eventTemplateModal" :title="$t('gameTemplates')" size="md" @ok="importTempate" :okTitle="$t('ok')" :cancelTitle="$t('cancel')">
        <b-input-group>
            <b-form-input v-model="inputSearchTemplate" type="text" id="search-template" placeholder="Search Templates" @input="onSearchTemplate" autocomplete="off"></b-form-input>
        </b-input-group>
        <Finder :tree="templates" @expand="onExpandTemplate" @select="onSelectTemplate" :key="templateKey"></Finder>
        </b-modal>
    </div>
</template>
<script>
import { Finder } from '@jledentu/vue-finder';
export default {
  components: {
    Finder
  },
  data() {
    return {
      inputSearchTemplate: '',
      templateKey: 0,
      templates: {
        id: "root",
        children: []
      }
    }
  },
  props: {
    mode: String,
    games: {
      type: Array,
      default: function(){return[]}
    },
    teams: {
      type:Array,
      default: function(){return[]}
    }
  }, 
  computed: {
    isEditMode: function(){
      return this.mode === 'edit';
    },
    isCreateMode: function(){
      return this.mode === 'create';
    }
  },   
  created() {
    this.$bobwinHub.$on('templateReceived', response => {
      this.templates.children = [];
      this.templateKey = 0;
      
      this.templateData = JSON.parse(response.data);
      this.templateData.forEach((value) => {
          let games = [];
          value.eventTemplateGames.forEach((game) => {
            games.push({ id: game.id, label: game.title, selectable: true, selected: game.enabled, options: game.eventTemplateGameOptions });
          });
          let data = {
            id: value.id,
            label: value.templateName,
            children: games,
            selectable: false
          };
          this.templates.children.push(data);
      });
      ++this.templateKey;
      if(this.$refs["templateModal"])
        this.$refs["templateModal"].show();
    });

    this.$bobwinHub.$on('templateSaved', response => {
      if(response.result !== 'success') {
        this.ShowErrorMessage(response.message);
      }
    });

  },
  methods: {
      onLoadTemplate(){
      this.CallHub({task: 'GetEventTemplates', callback: 'templateReceived', data: this.UserId});
    },
    onSaveTemplate(){
      this.$swal({
        title: this.$t('inputTemplateName'),
        input: 'text',
        inputAttributes: { autocapitalize: 'off'},
        animation: false,
        showCancelButton: true,
        showLoaderOnConfirm: true,
        confirmButtonText: this.$t('ok'),
        cancelButtonText: this.$t('cancel'),
        preConfirm: (title) => {
          
          let templateGames = [];
          this.games.forEach(function(game) {
            templateGames.push({
              title: game.title,
              eventTemplateGameOptions: game.options,
              enabled: true
            })
          });

          let template = {
            templateName: title,
            eventTemplateGames: templateGames,
            userId: this.UserId,
            teamNames: this.Distinct(this.teams,'name')
          };

          this.CallHub({task: 'SaveTemplate', callback: 'templateSaved', data: JSON.stringify(template)});
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      }).then((result) => {
        if (result.value) {
          this.$swal({
            icon: 'success',
            title: this.$t('templateSaved'),
            animation: false,
            confirmButtonText: this.$t('ok')
          })
        }
      });
    },
    
    onSelectTemplate({ selected }) {
      window.console.log(
        `Items with ${selected.join()} IDs are now selected`
      );
    },
    onExpandTemplate({ expanded }) {
      let selectedTemplateObj = this.templates.children.filter(template => template.id === expanded[1]);
      this.selectedTemplateGames = selectedTemplateObj[0].children;
    },
    importTempate(){
      this.selectedTemplateGames.forEach((value) => {        
        this.games.push({
          title: value.label,
          options: value.options,
          seedMoney: 0
        });
      });
      this.setDefaultGame();
      this.$emit('update:games',this.games);
    },
    setDefaultGame(){
      if(this.games.length > 0 && this.games.filter(game => game.isDefault == true).length == 0) {
        this.games[0].isDefault = true;
      }
    },
    onSearchTemplate(){
      if(this.inputSearchTemplate === '') {
        this.templateData = this.rawTemplateData;
      } else {
        this.templateData = this.rawTemplateData.filter((template) => template.templateName.toLowerCase().includes(this.inputSearchTemplate.toLowerCase()));
      }
      this.fetchTemplate();
    },
    filter(){
      return true;
    }
  }
}
</script>
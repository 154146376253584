<template>
    <b-form-group :label="label"> 
      <v-select v-model="selected" 
        :options="options"
        @input="onChange" 
        :reduce="category => category.value" label="text"
        :clearable="false"
        :disabled="disabled"
        class="form-control time-select">
      </v-select>
    </b-form-group>
</template>

<script lang="js">
export default {
  name: 'create-event-time',
  data () {
    return {
      selected: [],
      options: []
    }
  },
  props: {
    min: Number,
    max: Number,
    interval: Number,
    label: String,
    defaultValue: Number,
    disabled: Boolean
  },
  watch: {
    defaultValue: function(value){
      this.selected = value;
    }
  },
  created () {
    this.init();
  },
  methods: {
    init(){
        if(this.min >= 0 && this.max && this.interval){
            for (var i = this.min; i <= 24; i++) {
                this.options.push({value: i, text: '+ ' + i});
            }
            this.selected = this.defaultValue;
        }
    },
    onChange(){
        this.$emit('on-date-change', this.selected);
    }
  }
}
</script>
<template>
<section>
    <b-form-group :label="$t('category')" label-for="event-category">
      <v-select id="event-category" v-model="category.id"
        :options="categoryOptions" 
        :reduce="cate => cate.id" label="name"
        :disabled="mode=='edit'" 
        :clearable="false"
        @input="onCategoryChange"
        :placeholder="$t('selectCategory')" class="form-control">

        <template v-slot:no-options="{}">
          <div class="dot-opacity-loader"><span></span><span></span><span></span></div>
        </template>
      </v-select>

    </b-form-group>
</section>
</template>

<script lang="js">
export default {
  name: 'create-event-category',
  data () {
    return {
      category: { id: '' },
      categoryData: [],
      categoryOptions: []
    }
  },
  props: {
    eventCategoryId: String,
    mode: String
  },
  created () {
    this.getCategory();
  },
  watch: {
    'eventCategoryId': function(value){
      this.category.id = value;
      if(this.mode === 'edit'){
        this.onCategoryChange(this.eventCategoryId, true);
      }
    }
  },
  methods: {
    getCategory(){
      let options = [];
      this.CategoryData.forEach((cate) => {
        options.push({id: cate.id, name: cate.name});
      });
      this.categoryOptions = options;
    },
    onCategoryChange(value, isInit){
      let parent = this.CategoryData.filter(category => category.id === this.category.id);
      if(parent.length > 0){
        let selected = {
          id: this.category.id,
          name: parent[0].name,
          hasSubcategories: parent[0].hasSubcategories,
          isInit: isInit ? isInit : false
        };
        this.$emit('on-category-change', selected);
      }
    }
  }
}
</script>
<style scoped>
.dot-opacity-loader {
    width: 60px;
    height: 28px;
}
.dot-opacity-loader span{
    width: 10px;
    height: 10px;
    margin: 0 5px;
}
</style>